.footer {
    background-color: #162136;
    width: 100%;
    height: 100%;
    padding-bottom: 40px;
}

.content {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    margin-top: 30px;
}

.content h3 {
    color: #6f7685;
}

.navigate a {
    color: #c7c5d2;
    transition: .3s all;
    font-weight: 700;
}

.second {
    margin-left: 30px;
}

.navigate a:hover {
    color: #039be5;
}

@media screen and (max-width: 475px) {
    .navigate {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
    }

    .second {
        margin-left: 0;
        margin-top: 10px;
    }
}
