.header {
    width: 100%;
    min-height: 30vh;
    /*border: .1px solid;*/
    margin-top: 30px;
    background-color: #fff;
    /*border: 1px solid red;*/
    padding-bottom: 30px;
}

.header_content {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.logo img {
    height: 70px;
    width: auto;
}

.title_time {
    font-size: 11px;
    border: .1px solid;
    height: 100%;
    position: relative;
    top: -50px;
    left: 10px;
    color: #fff;
    background-color: #7BB03F;
    padding: 4px 5px;
    border-radius: 6px;
}

.title {
    font-size: 30px;
    margin: 0;
    color: #3D3D3D;
}

.left_header p {
    margin: 10px 0;
    font-size: 19px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.8);
}

.right_header img {
    height: 380px;
    width: auto;
}

.btn {
    display: flex;
    width: fit-content;
    align-items: center;
    justify-content: center;

    background-color: #039BE5;
    padding: 20px 30px;
    margin: 10px auto 10px auto;

    border-radius: 10px;
    transition: .3s all;
}

.content_item_item {
    margin: 10px auto;
}

.btn span {
    line-height: 0;
    font-size: 19px;
    color: #fff;
    font-weight: 500;
    padding: 0;
    margin-top: -3px;
}

.btn:hover {
    background-color: rgba(3, 155, 229, 0.84);
}


@media screen and (max-width: 1250px) {
    .header {
        margin-top: 10px;
        padding-bottom: 10px;
    }

    .title_time {
        top: -15px;
    }

    .logo img {
        height: 50px;
        width: auto;
    }

    .logo {
        width: 100%;
    }

    .title {
        max-width: 1000px;
        font-size: 40px;
    }

    .right_header img {
        height: auto;
        max-width: 300px;
        width: 100%;
    }
}

@media screen and (max-width: 1024px) {
    .title {
        max-width: 450px;
    }
}

@media screen and (max-width: 768px) {
    .title {
        font-size: 20px;
    }

    .title_time {
        top: -15px;
    }
}

@media screen and (max-width: 600px) {
    .title {
        font-size: 20px;
        max-width: 100%;
    }

    .title_time {
        top: -15px;
    }

    .right_header {
        display: none;
    }
}

@media screen and (max-width: 475px) {
    .title {
        font-size: 20px;
    }

    .title_time {
        top: -10px;
    }
}
