.about {
    padding-top: 20px;
}

.header p, .header h2 {
    margin: 0;
}

.header h2 {
    font-weight: 700;
    font-size: 43px;
    color: #162136;
}

.header p {
    font-size: 19px;
    font-weight: 400;
    color: #656C7B;
}

.text{
    font-size: 18px;
}